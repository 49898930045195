const axios = require("axios");
const zendeskOptions = () => {
  const options = {
    baseURL: "https://" + process.env.VUE_APP_ZENDESK_HELPCENTER,
  };
  return options;
};

export const getCategories = () =>
  axios.get("/de/categories.json", zendeskOptions());

export const getSections = () =>
  axios.get("/de/sections.json", zendeskOptions());
export const getSectionById = (id) =>
  axios.get(`/de/sections/${id}.json`, zendeskOptions());
export const getArticlesFromSection = (id) =>
  axios.get(`/de/sections/${id}/articles.json`, zendeskOptions());
