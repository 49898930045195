<template>
  <b-row id="faq-container" class="position-relative drop-shadow-box">
    <b-row id="faq-nav-row" class="w-100 position-absolute d-inline-flex">
      <b-col cols="5"
        ><div
          id="faq-dropdown"
          :class="{ expanded: faqMenuExpanded }"
          class="position-absolute"
        >
          <div
            id="faq-dropdown-title"
            class="w-100 d-inline-flex align-items-center justify-content-around"
            @click="faqMenuExpanded = !faqMenuExpanded"
          >
            <span class="blue text-uppercase bold"
              >Wähle dein Thema aus...</span
            >
            <div class="dropdown-arrow" :class="{ up: faqMenuExpanded }"></div>
          </div>

          <ul
            v-if="faqMenuExpanded"
            id="dropdown-content"
            class="blue text-uppercase mt-3"
          >
            <li
              v-for="item in faqSections"
              :key="item.id"
              @click="selectSection(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div></b-col
      >
      <b-col cols="7" class="d-inline-flex justify-content-between">
        <NavTab
          v-if="expandedSection.name"
          href="/faq"
          :label="expandedSection.name"
        />
        <NavTab
          class="mr-3"
          href="/contact"
          :label="$t('support.contact.tab_title')"
        />
      </b-col>
    </b-row>

    <b-col cols="5">
      <FAQItems
        :item="selectedArticle"
        :articles="expandedArticles"
        @articleClicked="showArticleDetail"
    /></b-col>
    <b-col>
      <FAQDetail
        v-if="selectedArticle.id"
        :item="selectedArticle"
        :expanded="detailExpanded"
        @toggleExpand="detailExpanded = !detailExpanded"
    /></b-col>
  </b-row>
</template>
<script>
import FAQItems from "@/components/faq/faq-items.vue";
import FAQDetail from "@/components/faq/faq-detail.vue";
import * as zenDeskAPI from "@/API/zendesk.js";
import NavTab from "@/components/base/nav-tab.vue";
export default {
  name: "FaqContainer",

  components: { FAQItems, FAQDetail, NavTab },
  data() {
    return {
      selectedArticle: {},
      faqSections: [],
      expandedSection: {},
      expandedArticles: [],
      detailExpanded: false,
      faqMenuExpanded: false,
    };
  },

  mounted() {
    zenDeskAPI.getSections().then((res) => {
      this.faqSections = res.data.sections.sort((a, b) =>
        a.name < b.name ? -1 : 1
      );

      let sectionId;
      if (this.$route.params.linkedSection) {
        // if a specific section was linked to
        sectionId = this.$route.params.linkedSection;
      } else {
        sectionId = this.faqSections[0].id;
      }
      this.getArticlesFromSection(sectionId).then(() => {
        if (this.$route.params.linkedArticle) {
          let articleToExpand = this.expandedArticles.find(
            (article) =>
              article.id === parseInt(this.$route.params.linkedArticle)
          );
          // show article detail only if article id is correct
          if (articleToExpand !== undefined) {
            this.showArticleDetail(articleToExpand);
          } else {
            // show first article of expanded articles as fallback
            this.showArticleDetail(this.expandedArticles[0]);
          }
        } else {
          this.showArticleDetail(this.expandedArticles[0]);
        }
        this.expandedSection = this.faqSections.find(
          (item) => item.id === parseInt(sectionId)
        );
        this.$store.commit("setIsPageLoading", false);
      });
    });
  },

  methods: {
    getArticlesFromSection(id) {
      return new Promise((resolve, reject) => {
        zenDeskAPI
          .getArticlesFromSection(id)
          .then((res) => {
            this.expandedArticles = res.data.articles;
            this.selectedArticle = res.data.articles[0];
            resolve(res);
          })
          .catch((e) => reject(e));
      });
    },
    selectSection(section) {
      // only fetch articles if new section is selected
      if (
        this.expandedArticles.length === 0 ||
        (this.expandedArticles.length > 0 &&
          this.expandedArticles[0].section_id !== section.id)
      ) {
        this.getArticlesFromSection(section.id);
      } else {
        // keep expended section and articles if section is the same
        this.selectedArticle = this.expandedArticles[0];
      }

      this.detailExpanded = false;
      this.faqMenuExpanded = false;
      this.expandedSection = section;
    },
    showArticleDetail(article) {
      this.selectedArticle = article;
      this.detailExpanded = false;
    },
  },
};
</script>
<style lang="scss">
#faq-container {
  min-height: 480px;
  #faq-nav-row {
    top: -40px;
    #faq-dropdown {
      width: 298px;
      height: 32px;
      top: -20px;
      left: 20px;
      border-radius: 8px;
      border: 1px solid $light-blue;
      background-image: linear-gradient(#0e1c24, #173242);
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5), 0 2px 10px rgba(0, 0, 0, 0.5),
        0 2px 4px rgba(0, 0, 0, 0.5);
      z-index: 15;
      display: flex;

      &.expanded {
        height: auto;
        display: block;
      }

      #faq-dropdown-title {
        height: 32px;
        cursor: pointer;
        &:hover {
          filter: $hover-shadow;
        }

        span {
          font-size: 16px;
        }
        .dropdown-arrow {
          height: 21px;
          width: 21px;
          background: url(~@/assets/img/icons/menu-dropdown-arrow-down.svg)
            center/100% 100% no-repeat;
          &.up {
            background: url(~@/assets/img/icons/menu-dropdown-arrow-up.svg)
              center/100% 100% no-repeat;
          }
        }
      }
      #dropdown-content {
        list-style-type: none;
        padding-inline-start: 20px;
        font-size: 15px;
        li {
          cursor: pointer;
          line-height: 24px;
          &:hover {
            filter: $hover-shadow;
          }
        }
      }
    }
  }
}
</style>
