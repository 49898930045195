<template>
  <b-row id="faq-items" align-v="center">
    <b-col cols="12" class="mt-3"
      ><p
        v-for="article in articles"
        :key="article.id"
        class="article-title medium blue text-uppercase mb-3"
        :class="{ selected: isArticleSelected(article.id) }"
        @click="$emit('articleClicked', article)"
      >
        {{ article.name }}
      </p></b-col
    >
  </b-row>
</template>

<script>
export default {
  name: "FAQItems",
  props: {
    item: {
      type: Object,
      required: true,
    },
    articles: {
      type: Array,
      required: false,
      default: null,
    },
  },

  methods: {
    isArticleSelected(id) {
      return id === this.item.id;
    },
  },
};
</script>

<style lang="scss">
#faq-items {
  height: 60px;
  color: #fff4b2;
  font-size: 20px;
  margin-left: -13px;
  margin-right: -13px;
  cursor: pointer;

  height: auto;

  .article-title {
    font-size: 24px;
    &:not(:last-of-type) {
      border-bottom: 2px solid #253a48;
    }
    &.selected {
      color: white;
      text-shadow: 0 0 8px rgba(77, 229, 255, 0.7), 0 0 8px $light-blue;
      &:hover {
        filter: none;
      }
    }
    &:hover {
      filter: $hover-shadow;
    }
  }
}
</style>
