<template>
  <b-row id="contact-container" align-v="center" align-h="around">
    <b-row id="contact-nav-row" class="w-100 position-absolute d-inline-flex">
      <NavTab
        v-if="$store.state.locale === 'de'"
        href="/faq"
        :label="$t('support.faq.tab_title')"
      />
      <NavTab
        class="mr-3"
        href="/contact"
        :label="$t('support.contact.tab_title')"
      />
    </b-row>

    <b-col
      id="contact-form-container"
      cols="12"
      xl="6"
      class="drop-shadow-box position-relative d-flex flex-column align-items-center h-100"
    >
      <b-form
        id="contact-support-form"
        class="w-100"
        @submit.prevent="handleRequestSubmit"
      >
        <b-row align-h="center" class="mt-3 mx-5">
          <b-col cols="12">
            <h4 class="text-center uppercase-subheadline">
              {{ $t("support.contact.support_request_headline") }}
            </h4></b-col
          >
          <b-col cols="6">
            <label for="subject-input" class="mb-0">{{
              $t("support.contact.label_subject")
            }}</label
            ><input
              id="subject-input"
              v-model="subject"
              type="text"
              class="form-control"
              :placeholder="$t('support.contact.placeholder_subject')"
              :state="subject.length > 5 && subject.length <= subjectMaxLength"
              :maxLength="subjectMaxLength"
              :class="{ error: subjectLengthError }"
              required
              @blur="checkSubjectLength"
            /><span
              v-if="subjectLengthError"
              class="text-danger small position-absolute"
              >{{
                $t("support.contact.subject_too_short", { 0: subjectMinLength })
              }}</span
            ></b-col
          ><b-col cols="6">
            <label for="email-input" class="mb-0">{{
              $t("profile.email")
            }}</label
            ><input
              id="email-input"
              v-model="$store.getters['user/currentUser'].email"
              type="email"
              class="form-control"
              disabled
              required /></b-col
          ><b-col cols="12" class="mt-3">
            <label for="support-message-input" class="mb-0">{{
              $t("support.contact.label_textarea")
            }}</label>
            <textarea
              id="support-message-input"
              v-model="userMessage"
              class="form-control w-100 text-normal"
              rows="7"
              :maxLength="messageMaxLength"
              :state="
                userMessage.length > 20 &&
                userMessage.length <= messageMaxLength
              "
              debounce="500"
              :class="{ error: messageLengthError }"
              :placeholder="$t('support.contact.placeholder_textarea')"
              @blur="checkMessageLength"
            /><span
              v-if="messageLengthError"
              class="text-danger small position-absolute mt-3"
              >{{
                $t("support.contact.message_to_short", { 0: messageMinLength })
              }}</span
            ></b-col
          >
          <b-col cols="12" class="d-flex justify-content-end">
            <span class="blue text-normal">{{
              $t("support.contact.textarea_chars_left", { 0: charsRemaining })
            }}</span></b-col
          >
          <b-col cols="8"
            ><button
              role="submit"
              :disabled="isSubmitButtonDisabled"
              class="ok-button-green round-new w-100 mt-3 mx-auto"
            >
              {{ $t("support.contact.button_submit") }}
            </button></b-col
          >
        </b-row></b-form
      >
      <div class="golden-divider"></div>
    </b-col>
    <b-col
      id="user-info-container"
      cols="12"
      xl="5"
      class="gold-border-box position-relative h-100"
      ><b-row align-h="center" class="h-100"
        ><h4 class="uppercase-subheadline mt-3">User</h4>
        <b-col cols="12"
          ><div id="user-avatar" class="gradient-indent glow mx-auto">
            <img
              draggable="false"
              alt="avatar"
              class="to-center-abs"
              :src="$store.getters['user/currentUser'].avatar_url"
            /></div></b-col
        ><b-col cols="12" class="d-flex flex-column align-items-center"
          ><p id="username" class="bold blue text-uppercase">
            {{ $store.getters["user/currentUser"].name }}
          </p>
          <p>
            <span class="text-uppercase text-white">{{
              $t("profile.email")
            }}</span
            ><span class="blue ml-3">{{
              $store.getters["user/currentUser"].email
            }}</span>
          </p></b-col
        ><b-col
          v-if="!$store.getters['user/currentUser'].email_verified_at"
          id="email-confirm-button"
          cols="12"
          class="d-inline-flex justify-content-center"
          ><button class="neutral-button-blue" @click="confirmEmail">
            {{ $t("profile.email_confirm") }}
          </button></b-col
        ></b-row
      ></b-col
    >
  </b-row>
</template>
<script>
import { confirmEmail } from "@/API/profile.js";
import { submitRequest } from "@/API/contact.js";
import NavTab from "@/components/base/nav-tab.vue";
export default {
  name: "ContactContainer",
  components: { NavTab },
  props: {
    browserSpecs: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      subject: "",
      userMessage: "",
      messageMinLength: 20,
      messageMaxLength: 600,
      subjectMinLength: 5,
      subjectMaxLength: 200,
      errorMessage: "",
      subjectLengthError: false,
      messageLengthError: false,
      isSending: false,
    };
  },
  computed: {
    charsRemaining() {
      return this.messageMaxLength - this.userMessage.length;
    },
    isSubmitButtonDisabled() {
      return (
        this.subject.length < this.subjectMinLength ||
        this.subject.length > this.subjectMaxLength ||
        this.userMessage.length < this.messageMinLength ||
        this.userMessage.length > this.messageMaxLength ||
        this.isSending
      );
    },
    specsText() {
      const specs = this.browserSpecs;
      return (
        "Browser: " +
        specs.browser.name +
        " " +
        specs.browser.version +
        "; Platform: " +
        specs.platform.vendor +
        " " +
        specs.platform.type +
        "; OS: " +
        specs.os.name +
        " " +
        specs.os.versionName +
        " " +
        specs.os.version
      );
    },
  },
  mounted() {
    this.$store.commit("setIsPageLoading", false);

    this.isSending = false;
  },
  methods: {
    confirmEmail() {
      confirmEmail()
        .then((res) => {
          this.$store.commit("popups/setAlertBannerContent", {
            alertText: this.$t("profile.email_confirm.alert", {
              0: this.$store.getters["user/currentUser"].email,
            }),
            alertHeading: this.$t("profile.email_confirm.alert_title"),
            type: "check",
          });
        })
        .catch((e) => {
          if (e.data) {
            if (e.data.details[0]) {
              this.errorMessage = e.data.details[0];
              this.$store.commit("popups/setAlertBannerContent", {
                alertText: this.errorMessage,
                alertHeading: this.$t("alerts.title.error"),
                type: "danger",
                variant: "danger",
              });
            } else {
              this.displayErrorMessage();
            }
          } else {
            this.displayErrorMessage();
          }
        });
    },
    handleRequestSubmit() {
      if (!this.$store.getters["user/currentUser"].email_verified_at) {
        this.$store.commit("popups/setAlertBannerContent", {
          alertText: this.$t("support.contact.not_verified"),
          alertHeading: this.$t("alerts.title.error"),
          type: "danger",
          variant: "danger",
        });
      } else {
        this.isSending = true;

        submitRequest({
          subject: this.subject,
          comment: this.userMessage + " | " + this.specsText,
        })
          .then((res) => {
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: this.$t("support.contact.inquiry_sent.message"),
              alertHeading: this.$t("alerts.title.success"),
              type: "check",
            });
            this.clearInputs();

            this.isSending = false;
          })
          .catch((e) => {
            this.displayErrorMessage();
            console.log(e);
            this.isSending = false;
          });
      }
    },
    clearInputs() {
      this.subject = "";
      this.userMessage = "";
    },
    checkSubjectLength() {
      if (
        this.subject.length < this.subjectMinLength ||
        this.subject.length > this.subjectMaxLength
      ) {
        this.subjectLengthError = true;
      } else {
        this.subjectLengthError = false;
      }
    },
    checkMessageLength() {
      if (
        this.userMessage.length < this.messageMinLength ||
        this.userMessage.length > this.messageMaxLength
      ) {
        this.messageLengthError = true;
      } else {
        this.messageLengthError = false;
      }
    },
  },
};
</script>
<style lang="scss">
#contact-container {
  height: 545px;
  position: relative;
  #contact-nav-row {
    top: -40px;
  }

  #contact-form-container {
    label {
      font-family: Ubuntu;
      font-size: 18px;
    }
    input.form-control {
      height: 40px;
      font-size: 20px;
    }
    .ok-button-green {
      height: 40px;
      font-size: 22px;
      &:disabled {
        filter: grayscale(100);
        pointer-events: none;
      }
    }
  }
  #user-info-container {
    .super-headline3-light {
      font-size: 34px;
    }
    #user-avatar {
      border-radius: 50%;
      width: 154px;
      height: 154px;
      overflow: hidden;
      position: relative;
      img {
        max-height: 100%;
      }
    }
    #username {
      font-size: 26px;
      text-shadow: 0 0 2px black, 0 0 2px rgba(0, 0, 0, 0.5);
    }
    #email-confirm-button {
      height: 32px;
    }
  }
}
</style>
