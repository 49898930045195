<template>
  <div class="faq-detail">
    <div
      class="selected-item-title w-100 mt-3 d-inline-flex align-items-center"
    >
      <h4 class="text-uppercase medium mx-3 mb-0">
        {{ item.title }}
      </h4>
    </div>
    <div
      class="body-text pt-3 pl-3"
      :class="[expanded ? 'expanded' : '']"
      v-html="item.body"
    ></div>
    <div
      v-if="!expanded && showExpandButton"
      id="expand-button"
      class="blue text-normal"
      @click="$emit('toggleExpand')"
    >
      {{ $t("info_badge.more_info") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQDetail",

  props: {
    item: {
      type: Object,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showExpandButton() {
      if (this.item.body) {
        let numberOfTags = (this.item.body.match(/</g) || []).length;
        let numberOfImgs = (this.item.body.match(/<img/g) || []).length;
        let numberOfLinks = (this.item.body.match(/<a/g) || []).length;
        // do not show expand button for articles containing only a single paragraph/list etc.
        if (numberOfTags <= 2) {
          return false;
        } else {
          // do not show expand button for articles containing only a single img wrapped in a p tag
          // do not show expand button for articles with only one paragraph containing a link
          if (
            (numberOfTags === 3 && numberOfImgs === 1) ||
            (numberOfTags === 4 && numberOfLinks === 1)
          ) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.faq-detail {
  .selected-item-title {
    background-color: rgba(13, 37, 53, 0.5);
    min-height: 44px;
    h4 {
      color: #fff8b7;
      font-size: 24px;
    }
  }
  .body-text {
    color: white;
    font-size: 21px;
    a {
      color: $light-blue;
      text-decoration: none;
      &:hover {
        filter: $hover-shadow;
      }
    }

    p,
    ul,
    ol,
    img {
      &:first-child {
        color: #fff8b7;
      }
      &:not(:first-child) {
        display: none;
      }
    }
    &.expanded {
      p,
      ul,
      ol,
      img {
        &:not(:first-child) {
          display: block;
        }
      }
    }
  }
  #expand-button {
    cursor: pointer;
    &:hover {
      filter: $hover-shadow;
    }
  }
}
</style>
