<template>
  <div id="faq-page" class="container mt-5">
    <router-view :browser-specs="browserSpecs" />
  </div>
</template>

<script>
import Bowser from "bowser";

export default {
  name: "FAQPage",
  data() {
    return {
      // contentTabs: [
      //   {
      //     title: "support.faq.tab_title",
      //     path: "/faq",
      //     name: "faq-topics",
      //     germanOnly: true
      //   },
      //   {
      //     title: "support.contact.tab_title",
      //     path: "/faq/contact",
      //     name: "contact-section"
      //   }
      // ],
      browserSpecs: {},
    };
  },
  mounted() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    this.browserSpecs = browser.parsedResult;
    this.$store.commit("setIsPageLoading", false);
  },
};
</script>

<style lang="scss" scoped>
#faq-page {
  position: relative;
  min-height: 100vh;
  padding: 48px;
  @media (max-width: 1100px) {
    padding: 0;
  }
}
</style>
